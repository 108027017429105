import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import janos from "assets/img/faces/janos.jpeg";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title} style={{color: "white"}}>About Me</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card plain>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                <img
                  width="30%"
                  src={janos}
                  alt="..."
                  className={imageClasses}
                />
              </GridItem>
              <br />
              <CardBody>
                <p className={classes.description} style={{color: "white"}}>
                  My name is Janos Kovesdi, I was born and raised in Budapest,
                  Hungary. My family and I moved to California in 1999. For the
                  past 16 years, I’ve been working in customer service. With my
                  extensive knowledge in the field of hospitality, I have
                  refined my interpersonal and communication skills to
                  accommodate any scenario that may present itself. Some of my
                  strengths are organizing and planning ahead. I use these
                  skills to think of every possible scenario that may arise, and
                  to avoid any possible difficulties ahead.
                  <br />
                  <br />
                  From a young age I’ve always been into computers. I watched
                  technology evolve throughout the past 28 years. I decided to
                  participate in the CISOSHARE CyberForward program, because
                  like everyone else, I to have had my fair share of encounters
                  with scammers and viruses. I realized, it was time to go on
                  the offense, and become a cyber security expert. I want to
                  help companies avoid incoming cyber attacks, and the headache
                  and stress it causes from any compromised data left in its
                  wake.
                  <br />
                  <br />I am interested in working as a Vulnerability Analyst. I
                  enjoy looking for weak points and vulnerabilities in a
                  security network so that I can find ways to build and
                  reinforce them, preventing attacks and/or data leaks for the
                  present and future of a companies well-being. I am also
                  interested in performing Penetration testing or becoming an
                  Incident Responder. I find it fascinating to go out to the
                  field and to discover what happened and how can we fix it and
                  keep it from happening again. <br />
                  <br />I am currently perusing certificates in order to expand
                  my knowledge in the overall field of cyber security. My
                  five-year goal is to become a cyber security expert and to
                  thrive in this ever evolving field.
                </p>
              </CardBody>
              {/* <CardFooter className={classes.justifyCenter}>
               
              </CardFooter> */}
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
