import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function Education() {
  const classes = useStyles();
  return (
    <div
      className={classes.section}
      style={{
        background: "black",
        borderRadius: 10,
        boxShadow: "0px 0px 12px 0px rgba(226, 192, 68, 0.5)",
        padding: 30,
      }}
    >
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2
            className={classes.title}
            style={{ color: "rgba(226, 192, 68, 1)" }}
          >
            Education
          </h2>
          <h4 className={classes.title} style={{ color: "#fff" }}>
            My Studies
          </h4>
        </GridItem>
      </GridContainer>

      <GridContainer justify="flex-start">
        <GridItem cs={12} sm={3}>
          <div style={{ color: "#fff" }}>October 2020</div>
          <div style={{ color: "rgba(226, 192, 68, 1)" }}>
            CISOSHARE San Clemente
          </div>
        </GridItem>
        <GridItem cs={12} sm={9}>
          CyberForward Certificate of Completion Talent Participant in
          CyberForward program designed to prepare candidates for third-party
          assessor, security policy analyst, and vulnerability analyst roles.
          Gain an understanding of security domains, terminology, frameworks,
          policies, incident response and vulnerability management.
        </GridItem>
      </GridContainer>

      <GridContainer justify="flex-start" style={{ marginTop: 20 }}>
        <GridItem cs={12} sm={3}>
          <div style={{ color: "#fff" }}>2008 - 2010</div>
          <div style={{ color: "rgba(226, 192, 68, 1)" }}>
            University of Phoenix
          </div>
        </GridItem>
        <GridItem cs={12} sm={9}>
          Associate of Arts in Business Business Management <br />
          GPA: 3.91
        </GridItem>
      </GridContainer>

      <GridContainer justify="flex-start" style={{ marginTop: 20 }}>
        <GridItem cs={12} sm={3}>
          <div style={{ color: "#fff" }}>2004</div>
          <div style={{ color: "rgba(226, 192, 68, 1)" }}>
            The Art Institute San Diego
          </div>
        </GridItem>
        <GridItem cs={12} sm={9}>
          Media Arts and Animation
        </GridItem>
      </GridContainer>
    </div>
  );
}
