import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function Attributes() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
       <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2
            className={classes.title}
            style={{ color: "#fff" }}
          >
            Skills & Certificates
          </h2>
        </GridItem>
      </GridContainer>
      <GridContainer justify="left" spacing={0}>
        <GridItem cs={12} md={6} style={{ marginBottom: 20 }}>
          <div
            className={classes.boxes}
            style={{
              boxShadow: "0px 0px 8px 0px rgba(255,255,255, 0.5)",
              padding: 1,
              borderRadius: 10,
            }}
          >
            <h4
              style={{ color: "#fff", fontWeight: "bold" }}
              className={classes.description}
            >
              Adobe Photoshop
            </h4>
            <h4 className={classes.description} style={{ color: "#fff" }}>
              +20 year experience
            </h4>
          </div>
        </GridItem>
        <GridItem cs={12} md={6} style={{ marginBottom: 20 }}>
          <div
            className={classes.boxes}
            style={{
              boxShadow: "0px 0px 8px 0px rgba(255,255,255, 0.5)",
              padding: 1,
              borderRadius: 10,
            }}
          >
            <h4
              style={{ color: "#fff", fontWeight: "bold" }}
              className={classes.description}
            >
              Microsoft Office
            </h4>
            <h4 className={classes.description} style={{ color: "#fff" }}>
              +20 year experience
            </h4>
          </div>
        </GridItem>
        <GridItem cs={12} md={6} style={{ marginBottom: 20 }}>
          <div
            className={classes.boxes}
            style={{
              boxShadow: "0px 0px 8px 0px rgba(255,255,255, 0.5)",
              padding: 1,
              borderRadius: 10,
            }}
          >
            <h4
              style={{ color: "#fff", fontWeight: "bold" }}
              className={classes.description}
            >
              CISOSHARE CyberForward Internship
            </h4>
            <h4 className={classes.description} style={{ color: "#fff" }}>
              2020
            </h4>
          </div>
        </GridItem>
        <GridItem cs={12} md={6} style={{ marginBottom: 20 }}>
          <div
            className={classes.boxes}
            style={{
              boxShadow: "0px 0px 8px 0px rgba(255,255,255, 0.5)",
              padding: 1,
              borderRadius: 10,
            }}
          >
            <h4
              style={{ color: "#fff", fontWeight: "bold" }}
              className={classes.description}
            >
              Fortinet's Network Security Expert NSE1
            </h4>
            <h4 className={classes.description} style={{ color: "#fff" }}>
              2020
            </h4>
          </div>
        </GridItem>
        <GridItem cs={12} md={6} style={{ marginBottom: 20 }}>
          <div
            className={classes.boxes}
            style={{
              boxShadow: "0px 0px 8px 0px rgba(255,255,255, 0.5)",
              padding: 1,
              borderRadius: 10,
            }}
          >
            <h4
              style={{ color: "#fff", fontWeight: "bold" }}
              className={classes.description}
            >
              Fortinet's Network Security Expert NSE2
            </h4>
            <h4 className={classes.description} style={{ color: "#fff" }}>
              2020
            </h4>
          </div>
        </GridItem>
        <GridItem cs={12} md={6} style={{ marginBottom: 20 }}>
          <div
            className={classes.boxes}
            style={{
              boxShadow: "0px 0px 8px 0px rgba(255,255,255, 0.5)",
              padding: 1,
              borderRadius: 10,
            }}
          >
            <h4
              style={{ color: "#fff", fontWeight: "bold" }}
              className={classes.description}
            >
              Qualys Vulnerability Management Certification
            </h4>
            <h4 className={classes.description} style={{ color: "#fff" }}>
              2020
            </h4>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
