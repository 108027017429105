import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkHistory() {
  const classes = useStyles();
  return (
    <div
      className={classes.section}
      style={{
        background: "black",
        borderRadius: 10,
        boxShadow: "0px 0px 12px 0px rgba(226, 192, 68, 0.5)",
        padding: 30,
      }}
    >
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2
            className={classes.title}
            style={{ color: "rgba(226, 192, 68, 1)" }}
          >
            Experience
          </h2>
          <h4 className={classes.title} style={{ color: "#fff" }}>
            Work History
          </h4>
        </GridItem>
      </GridContainer>

      <GridContainer justify="flex-start">
        <GridItem cs={12} sm={3}>
          <div style={{ color: "#fff" }}>2020 CISOSHARE</div>
          <div style={{ color: "rgba(226, 192, 68, 1)" }}>CyberForward</div>
        </GridItem>
        <GridItem cs={12} sm={9}>
          Cyber Security Analyst Intern
          <ul>
            <li>
              Created Third-Party Risk Management Assessment (TPRMA) and
              improved security policies in mock-consulting environment
            </li>
            <li>Managed vulnerabilities found in SOC reports using Qualys</li>
          </ul>
        </GridItem>
      </GridContainer>

      <GridContainer justify="flex-start" style={{ marginTop: 20 }}>
        <GridItem cs={12} sm={3}>
          <div style={{ color: "#fff" }}>2004 - Present</div>
          <div style={{ color: "rgba(226, 192, 68, 1)" }}>
            Resort Parking Services Inc.
          </div>
        </GridItem>
        <GridItem cs={12} sm={9}>
          Valet Attendant
          <ul>
            <li>
              Park and Retrieve customer vehicles in a safe and efficient manner
            </li>
          </ul>
        </GridItem>
      </GridContainer>

      <GridContainer justify="flex-start" style={{ marginTop: 20 }}>
        <GridItem cs={12} sm={3}>
          <div style={{ color: "#fff" }}>2009 - Present</div>
          <div style={{ color: "rgba(226, 192, 68, 1)" }}>The Vintage Club</div>
        </GridItem>
        <GridItem cs={12} sm={9}>
          Banquet Server
          <ul>
            <li>Ensure guest experience a memorable one</li>
          </ul>
        </GridItem>
      </GridContainer>
    </div>
  );
}
