import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  return (
    <div
      className={classes.section}
      style={{ alignSelf: "center", display: "flex", flexDirection: "column" }}
    >
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h4 className={classes.description} style={{ color: "#fff" }}>
            kovesdijr@gmail.com
          </h4>
        </GridItem>
      </GridContainer>
      <div
        style={{
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          color="transparent"
          size="lg"
          href="mailto:kovesdijr@gmail.com"
          target="_blank"
        >
          Let's Connect!
        </Button>
      </div>
    </div>
  );
}
